<template>
  <Page :title="'首页'">
    <div class="index-page">
      <header id="header-home">
        <section class="comp-header-links hidden-xs">
          <div class="container">
            <div class="row">
              <div class="home-top pt-3">
                <div class="col-sm-6 float-left">
                  <div class="home-logo">
                    <router-link to="/" class="nav-link"><img src="../assets/images/home-logo.png" alt="XX公共法律服务平台"></router-link>
                  </div>
                  <div class="header-title">法宣在线</div>
                </div>
                <div class="col-sm-6 float-left">
                  <div class="login float-right" v-if="loginStatus"> <a href="javascript:void(0)" id="cniil_wza">
                    <a-icon type="eye" />
                    无障碍阅读</a>
                    <router-link to="/Register" target="_blank" class="public-login">注册</router-link>
                    <router-link to="/Login" class="public-people">社会公众登录</router-link>
                    <router-link to="/Login" class="public-people">服务人员登录</router-link>
                  </div>
                  <div class="login float-right" v-if="!loginStatus"> <a href="/my#/workorder" class="public-center">个人中心</a> <a href="javascript:void(0)" class="public-center" @click="exit">退出</a> </div>
                </div>
              </div>
            </div>
          </div>
          <nav class="navbar navbar-expand-sm navbar-dark">
            <div class="container">
              <ul class="navbar-nav justify-content-center">
                <li class="nav-item active">
                  <router-link to="/" class="nav-link">首页</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/servicehall" class="nav-link" @click="e => e.preventDefault()">服务大厅</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/news" class="nav-link" target="_blank">服务动态</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/numberservice" class="nav-link" target="_blank">数说服务</router-link>
                </li>
                <li class="nav-item">
                  <a-dropdown> <a class="nav-link" @click="e => e.preventDefault()"> 法律知识库
                    <a-icon type="caret-down" :style="{fontSize:'16px'}" />
                  </a>
                    <a-menu slot="overlay">
                      <a-menu-item> <a href="http://search.chinalaw.gov.cn/search2.html" class="dropdown-item" target="_blank">基本法律法规</a> </a-menu-item>
                      <a-menu-item>
                        <router-link to="/law_res/list" class="dropdown-item" target="_blank">司法行政法律法规</router-link>
                      </a-menu-item>
                      <a-menu-item>
                        <router-link to="/casebase" class="dropdown-item" target="_blank">司法行政案例库</router-link>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </li>
                <li class="nav-item">
                  <a-dropdown> <a class="nav-link" @click="e => e.preventDefault()"> 法律工具
                    <a-icon type="caret-down" :style="{fontSize:'16px'}"/>
                  </a>
                    <a-menu slot="overlay">
                      <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/calculator/t/calculatorList.html"
                                       target="_blank">法律计算器</a> </a-menu-item>
                      <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/law/t/lawDocList.html"
                                       target="_blank">法律文书</a> </a-menu-item>
                      <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/law/t/lawSuggDocList.html"
                                       target="_blank">法律意见书</a> </a-menu-item>
                      <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/sentence/problem/list/t/problemList.html"
                                       target="_blank">类案分析</a> </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </li>
                <li class="nav-item">
                  <a-dropdown> <a class="nav-link" @click="e => e.preventDefault()"> 法务地图
                    <a-icon type="caret-down"  :style="{fontSize:'16px'}"/>
                  </a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <router-link to="/legalmap" class="dropdown-item" target="_blank">地图模式</router-link>
                      </a-menu-item>
                      <a-menu-item>
                        <router-link to="/allorgperson" class="dropdown-item" target="_blank">列表模式</router-link>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </li>
              </ul>
            </div>
          </nav>
          <div class="container">
            <div class="row">
              <div class="hero">
                <div class="hero-welcome col-sm-7 float-left">
                  <div class="hero-welcome-title gd-sm-hide"> 公共法律服务一网通办 </div>
                  <div class="hero-search-title" v-html="'普惠 均等 便捷 高效 精准 智能'"></div>
                  <div class="hero-search">
                    <div class="hero-search-form">
                      <form>
                        <input type="text" @keyup.enter="search" class="form-control input-lg" v-model="keyword" placeholder="请输入关键字查询法律问题">
                        <span class="input-group-addon btn btn-primary" @click="search">
                      <a-icon type="search" />
                      搜索</span>
                      </form>
                    </div>
                    <div class="hot-links"> <span>本站热词：</span>
                      <router-link  target="_blank" to="/search?keyword=法律援助">法律援助</router-link>
                      <router-link   target="_blank" to="/search?keyword=公证"> 公证</router-link>
                      <router-link   target="_blank" to="/search?keyword=社保">社保</router-link>
                      <router-link   target="_blank" to="/search?keyword=司法鉴定">司法鉴定</router-link>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 float-right consulting-service pb-3">
                  <div class="consulting-cases clearFix">
                    <div class="tab ml-0">
                      <a-tabs default-active-key="1">
                        <a-tab-pane key="1" tab="咨询服务">
                          <div  class="tab-pane">
                            <Collection  name="workorder" :query="{ order_type: '咨询'}"
                                         v-slot="{ collection }"  :limit=6 >
                              <Consult :refObj="collection"></Consult>
                            </Collection>
                          </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" disabled > <span slot="tab" class="Consultation-statistics">当前共有<span class="Consultation-number">51231</span>次统计</span> </a-tab-pane>
                      </a-tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
      <div class="col-sm-12 notice-box">
        <div  class="container">
          <div  class="row">
            <div class="notice">
              <h5 class="float-left">
                <a-icon type="sound" />
                通知公告</h5>
              <div class="box float-right">
                <div class="winBox">
                  <ul class="scroll">
                    <Collection  name="post" :query="{ target: '0176b83485d48a83829876b76511009e' }"
                                 v-slot="{ collection }"  :limit=9 >
                      <Notice :refObj="collection"></Notice>
                    </Collection>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <article id="page-index">
        <div class="container facilitate-serve">
          <div class="row">
            <div class="col-sm-12 mb-5 mt-5 p-0">
              <div class="col-sm-4 float-left">
                <template>
                  <a-carousel autoplay>
                    <div><img src="../assets/banner/huandeng01.png"></div>
                    <div><img src="../assets/banner/huandeng02.png"></div>
                  </a-carousel>
                </template>
              </div>
              <div class="col-sm-8 float-right pr-0">
                <div class="picLinks">
                  <ul class="clearfix">
                    <li><a target="_blank" href="/"><img src="../assets/banner/ydyl-td.png"></a></li>
                    <li><a target="_blank" href="/"><img src="../assets/banner/nmg-td.jpg"></a></li>
                    <li><a target="_blank" href="/"><img src="../assets/banner/rmmyd-td.png"></a></li>
                    <li><a target="_blank" href="/"><img src="../assets/banner/jr-td.jpg"></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="portal-box person mb-5">
          <div class="container">
            <div class="portal-box-bd">
              <div class="portal-sub-box">
                <div class="portal-sub-box-hd">
                  <h3 class="portal-mod-title float-left">法律服务</h3>
                  <router-link  to="/servicehall" target="_blank" class="more">更多服务<i class="i-right hover"></i></router-link>
                </div>
                <div class="portal-sub-box-bd">
                  <div class="info-card gd-row gd-row-gutter-30 gd-row-md-4 gd-row-sm-3">
                    <div class="info-card-col gd-col gd-col-4">
                      <div class="info-card-item bizcard-item">
                        <div class="info-card-item-icon"><img src="../assets/icon/law-serve-nav-icon01.png" alt="法律援助"/></div>
                        <div class="info-card-item-rightbox">
                          <div class="info-card-title" title="法律援助">法律援助</div>
                          <div class="info-card-item-rightbox-links">
                            <div>
                              <router-link to="/legalaid" target="_blank">
                                <dt>法律援助</dt>
                              </router-link>
                            </div>
                            <div>
                              <router-link to="/legalaid" target="_blank"><span data-hover="在线查找">援助流程</span></router-link>
                            </div>
                            <div>
                              <router-link to="/legalaid" target="_blank"><span data-hover="在线预申请">援助机构</span></router-link>
                            </div>
                            <div class="tjsb">
                              <router-link to="/lawassistance"><span data-hover="在线申办">在线申办</span></router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="info-card-col gd-col gd-col-4">
                      <div class="info-card-item bizcard-item">
                        <div class="info-card-item-icon"><img src="../assets/icon/law-serve-nav-icon02.png" alt="公证服务"/></div>
                        <div class="info-card-item-rightbox">
                          <div class="info-card-title" title="公证服务">公证服务</div>
                          <div class="info-card-item-rightbox-links">
                            <div>
                              <router-link to="/notarizationServe" target="_blank"><span>查公证员</span></router-link>
                            </div>
                            <div>
                              <router-link to="/notarizationServe" target="_blank"><span>查公证处</span></router-link>
                            </div>
                            <div class="tjsb"><a href="https://api.egongzheng.com/flow/v2/gs12348/index.htm?ticket=BD911B0FCAC92896B2575916D3723DB5" target="_blank"><span data-hover="在线申办">在线申办</span></a></div>
                            <div class="tjsb">
                              <router-link to="/notarization" ><span>预约公证</span></router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="info-card-col gd-col gd-col-4">
                      <div class="info-card-item bizcard-item">
                        <div class="info-card-item-icon"><img src="../assets/icon/law-serve-nav-icon03.png" alt="司法鉴定"/></div>
                        <div class="info-card-item-rightbox">
                          <div class="info-card-title" title="司法鉴定">司法鉴定</div>
                          <div class="info-card-item-rightbox-links">
                            <div>
                              <router-link to="/forensicexpertise" target="_blank">
                                <dt>司法鉴定</dt>
                              </router-link>
                            </div>
                            <div>
                              <router-link to="/forensicexpertise" target="_blank"><span>鉴定员</span></router-link>
                            </div>
                            <div>
                              <router-link to="/forensicexpertise" target="_blank"><span>鉴定机构</span></router-link>
                            </div>
                            <div class="tjsb">
                              <router-link to="/appraisal"><span>预约鉴定</span></router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="info-card-col gd-col gd-col-4">
                      <div class="info-card-item bizcard-item">
                        <div class="info-card-item-icon"><img src="../assets/icon/law-serve-nav-icon04.png" alt="公证服务"/></div>
                        <div class="info-card-item-rightbox">
                          <div class="info-card-title" title="人民调解">人民调解</div>
                          <div class="info-card-item-rightbox-links">
                            <div>
                              <router-link to="/peoplemediate" target="_blank">
                                <dt>人民调解</dt>
                              </router-link>
                            </div>
                            <div>
                              <router-link to="/peoplemediate" target="_blank"><span>查调解员</span></router-link>
                            </div>
                            <div>
                              <router-link to="/peoplemediate" target="_blank"><span>查调委会</span></router-link>
                            </div>
                            <div class="tjsb">
                              <router-link to="/mediate"><span>预约调解</span></router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="info-card-col gd-col gd-col-4">
                      <div class="info-card-item bizcard-item">
                        <div class="info-card-item-icon"><img src="../assets/icon/law-serve-nav-icon05.png" alt="律师服务"/></div>
                        <div class="info-card-item-rightbox">
                          <div class="info-card-title" title="律师服务">律师服务</div>
                          <div class="info-card-item-rightbox-links">
                            <div>
                              <router-link to="/lawyerserve" target="_blank">
                                <dt>律师服务</dt>
                              </router-link>
                            </div>
                            <div>
                              <router-link to="/lawyerserve" target="_blank"><span>查律所</span></router-link>
                            </div>
                            <div>
                              <router-link to="/lawyerserve" target="_blank"><span>查律师</span></router-link>
                            </div>
                            <div class="tjsb">
                              <router-link to="/lawyer"><span>预约律师</span></router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="info-card-col gd-col gd-col-4">
                      <div class="info-card-item bizcard-item">
                        <div class="info-card-item-icon"><img src="../assets/icon/law-serve-nav-icon06.png" alt="仲裁服务"/></div>
                        <div class="info-card-item-rightbox">
                          <div class="info-card-title" title="仲裁服务">仲裁服务</div>
                          <div class="info-card-item-rightbox-links">
                            <div><router-link to="/arbitrationserve" target="_blank"><dt>仲裁服务</dt></router-link></div>
                            <div class="zydsb">
                              <router-link to="/arbitrationserve" target="_blank"><span>仲裁机构</span></router-link>
                            </div>
                            <div class="zyzsb">
                              <router-link to="/arbitrationserve" target="_blank"><span>仲裁员</span></router-link>
                            </div>
                            <div class="tjsb">
                              <router-link to="/arbitrationapply"><span>预约仲裁</span></router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="portal-box-bd">
              <div class="portal-sub-box">
                <div class="portal-sub-box-hd">
                  <div class="portal-mod-title float-left">特别通道</div>
                </div>
                <div class="portal-sub-box-bd">
                  <div class="container-outer-wrapper">
                    <div class="container-outer-wrapper-container">
                      <div class="text-card col-lg-12 col-md-12 col-sm-12 p-0">
                        <div class="gd-col col-sm-3  text-card-col">
                          <a href="http://search.chinalaw.gov.cn/search2.html" target="_blank" class="text-card-item"><a-icon type="book" />基本法律法规库</a>
                        </div>
                        <div class="gd-col col-sm-3  text-card-col">
                          <router-link to="/law_res/list" target="_blank" class="text-card-item"><a-icon type="audit" />司法行政法律法规</router-link>
                        </div>
                        <div class="gd-col col-sm-3  text-card-col">
                          <router-link to="/casebase" target="_blank" class="text-card-item"><a-icon type="switcher" />司法行政案例库</router-link>
                        </div>
                        <div class="gd-col col-sm-3  text-card-col">
                          <router-link to="/expert" target="_blank" class="text-card-item"><a-icon type="solution" />法律专家库</router-link>
                        </div>
                        <div class="gd-col col-sm-3  text-card-col">
                          <router-link to="/volunteer/add" target="_blank" class="text-card-item"><a-icon type="usergroup-add" />公共法律志愿者库</router-link>
                        </div>
                        <div class="gd-col col-sm-3  text-card-col">
                          <router-link to="/evaluation" target="_blank" class="text-card-item"><a-icon type="bank" />满意度评价（机关履职评价）</router-link>
                        </div>
                        <div class="gd-col col-sm-3  text-card-col">
                          <router-link to="/zan" target="_blank" class="text-card-item"><a-icon type="like" />我要点赞（机构好评）</router-link>
                        </div>
                        <div class="gd-col col-sm-3  text-card-col">
                          <router-link to="/zan" target="_blank" class="text-card-item"><a-icon type="environment" />半小时服务圈（法律服务地图）</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-news mb-5">
          <div class="container">
            <div class="row">
              <div class="portal-box-hd pb-3 overflow-hidden">
                <div class="leftbox">
                  <h1 class="portal-mod-title">政策新闻</h1>
                  <span class="tip">足不出户知晓天下事！</span>
                </div>
                <router-link to="/news" class="more-btn">更多动态<i class="i-right"></i></router-link>
              </div>
              <div class="col-sm-12 p-0">
              <div class="col-sm-5 pl-0 float-left">
                <section class="swiper-container">
                  <Collection  name="post" :query="{ sort_no: '100'}"  v-slot="{ collection }"  :limit=9 >
                    <a-carousel autoplay>
                      <div class="swiper-slide" v-for="value in collection" :key="value.post_id">
                        <router-link  :to="`/article/detail/${value.post_id}`" :title="value.post_title"><img :src="getPostCoverURL(value)">
                        </router-link>
                        <div class="swiper-slide-title">
                          <p>{{ value.post_title }}</p>
                        </div>
                      </div>
                    </a-carousel>
                  </Collection>
                </section>
              </div>
              <div class="col-sm-5 newshot float-right">
                <div class="tab">
                  <a-tabs default-active-key="1">
                    <a-tab-pane key="1" tab="司法行政要闻">
                      <div  class="tab-pane">
                        <Collection  name="post" :query="{ target: '0176141e93568a8380377603faf907ff' }"
                                     v-slot="{ collection }"  :limit=9 >
                          <Article :refObj="collection" ></Article>
                        </collection>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="普法动态">
                      <div  class="tab-pane">
                        <Collection  name="post"   :query="{ target: '0176141ed1ea8a8380377603faf90801' }"
                                     v-slot="{ collection }"  :limit=9 >
                          <Article :refObj="collection"></Article>
                        </collection>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="portal-box portal-rating portal-rating-city rate">
          <div class="container">
            <div class="row">
              <div class="portal-box-hd">
                <div class="leftbox">
                  <h1 class="portal-mod-title">服务好差评</h1>
                  <span class="tip">本月统计数据</span> </div>
                 <a target="_blank" class="more-btn">查看更多<i class="i-right"></i></a> </div>
                 <div class="gd-row portal-rating-list">
                <div class="gd-col-3 portal-rating-item"> <span class="portal-rating-item-tip">办件数</span>
                  <div class="portal-rating-item-data" id="affairCount"><span>315.9</span>万条</div>
                </div>
                <div class="gd-col-3 portal-rating-item"> <span class="portal-rating-item-tip">收到好评数</span>
                  <div class="portal-rating-item-data" id="badCount"><span>415</span>条</div>
                </div>
                <div class="gd-col-3 portal-rating-item"> <span class="portal-rating-item-tip">收到差评数</span>
                  <div class="portal-rating-item-data"> <span id="badChance">231</span>条 </div>
                </div>
                <div class="gd-col-3 portal-rating-item"> <span class="portal-rating-item-tip" id="average-score">平均分</span>
                  <div class="portal-rating-item-data"> <span id="avgScore">9.9</span>分
                    <ul class="rate-picker small">
                      <Rate></Rate>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
        <div class="home-number-server container">
          <div class="row">
            <div class="number-say-service mt-5">
              <div class="hd">
                <ul class="nav nav-tabs">
                  <li role="presentation"><a @click=" serverindex = 0" :class="{active : serverindex == 0}">累计业务</a></li>
                  <li role="presentation"><a @click=" serverindex = 1" :class="{active : serverindex == 1}">当日业务</a></li>
                  <li role="presentation"><a @click=" serverindex = 2" :class="{active : serverindex == 2}">服务机构</a></li>
                  <li role="presentation"><a @click=" serverindex = 3" :class="{active : serverindex == 3}">服务人员</a></li>
                  <router-link  to="/servicehall" target="_blank" class="more">更多数据<i class="i-right hover"></i></router-link>
                </ul>
              </div>
            </div>
            <div class="slideNumBox mt-4 overflow-hidden">
              <div class="bd tab-content">
                <div class="tab-pane" :class="{active : serverindex == 0}">
                  <ul>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="234234"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">法律咨询</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="231123"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">法律援助案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="9752873"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">公证案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="6"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">司法鉴定案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="9877"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">人民调解案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="6655"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">律师案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="6544"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">基层法律服务案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="361"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">投诉受理</span></li>
                  </ul>
                </div>
                <div class="tab-pane"  :class="{active : serverindex == 1}">
                  <ul>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="3423"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">法律咨询</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="564"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">法律援助案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="3434"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">公证案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="542"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">司法鉴定案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="222"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">人民调解案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="2856"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">律师案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="22"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">基层法律服务案件 </span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="339"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">投诉受理</span></li>
                  </ul>
                </div>
                <div class="tab-pane"  :class="{active : serverindex == 2}">
                  <ul>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="197403"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">公共法律服务中心</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="187403"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">法律援助机构</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="82178"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">公证处</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="172975"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">司法鉴定机构</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="88222"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">人民调解委员会</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="280229"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">律师事务所</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="1307"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">基层法律服务所</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="361"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">仲裁委员会</span></li>
                  </ul>
                </div>
                <div class="tab-pane"  :class="{active : serverindex == 3}">
                  <ul>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="197403"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">司法行政工作人员</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="187403"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">法律援助律师</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="82178"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">公证员</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="172975"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">司法鉴定人</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="88222"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">人民调解员</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="280229"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">律师</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="1307"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">基层法律服务工作者</span></li>
                    <li class="card card-aylen">
                      <div class="date">
                        <div class="card-datapanel-data card-datapanel-highlight">
                          <NumberGrow :value="361"></NumberGrow>
                        </div>
                      </div>
                      <span class="card-datapanel-title">仲裁员</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <affixfor-right></affixfor-right>
        <div class="footer-link footer">
          <div class="footer-link-container">
              <div class="foot-nav-item center w-25 float-left p-3">
                <div class="footer-nav footer-nav-sitemap">
                <div class="footer-nav-hd"><h3 class="footer-nav-title">网站信息</h3></div>
                <div class="footer-nav-bd">
                  <router-link to="/" class="footer-nav-link" target="_blank">网站介绍</router-link>
                  <router-link to="/" class="footer-nav-link" target="_blank">网站纠错</router-link>
                  <router-link to="/" class="footer-nav-link" target="_blank">隐私政策</router-link>
                  <router-link to="/" class="footer-nav-link" target="_blank">服务建议</router-link>
                </div>
              </div></div>
              <div class="foot-nav-item center w-25 float-left p-3">
                <div class="footer-nav footer-nav-contact">
                  <div class="footer-nav-hd"><h3 class="footer-nav-title">联系我们</h3></div>
                  <div class="footer-nav-bd">
                    <div class="footer-nav-link">公共法律服务热线：12348 </div>
                    <div class="footer-nav-link">400-659-2288(技术支持)</div>
                  </div>
                </div>
              </div>
            <div class="foot-nav-item center w-25 float-left p-3">
              <div class="footer-mp footer-nav">
                <a href="javascript:;" class="footer-mp-item">XX省人民政府门户网站</a>
                <a href="javascript:;" class="footer-mp-item">XX省司法厅</a>
                <a href="javascript:;" class="footer-mp-item">xx省公法掌上服务</a>
              </div>
            </div>
            <div class="foot-nav-item center w-25 float-left p-3">
                <div class="support">
                  <a href="javascript:;" class="footer-mp-item support-minapp">xx省政务平台</a>
                  <div class="support-bottom">
                    <a class="support-link" href="" target="_blank"><span>政府网站找错</span></a>
                    <a class="support-party" href="" target="_blank"><span>党政机关</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </article>
    </div>
    <Footer></Footer>
  </Page>
</template>
<script>
import Footer from '../components/footer/Footer.vue'
import NumberGrow from '../components/base/NumberGrow'
import Article from '../components/base/Article.vue'
import Collection from '../components/Collection.vue'
import Consult from '../components/base/Consult.vue'
import Notice from '../components/base/Notice.vue'
import Rate from '../components/base/Rate.vue'
import moment from 'moment'
import AffixforRight from '@/components/layout/AffixforRight'
export default {
  name: 'Home',
  components: {
    AffixforRight,
    Footer,
    NumberGrow,
    Collection,
    Consult,
    Notice,
    Article,
    Rate
  },
  data () {
    return {
      keyword: '',
      serverindex: 0,
      lawActive: '0176141e93568a8380377603faf907ff',
      org: [],
      provider_type: '公证机构',
      time: '',
      styleShow: -1,
      loginStatus: true
    }
  },
  methods: {
    styleActive (who) {
      this.styleShow = who
    },
    search () {
      if (this.keyword.length > 0) {
        this.$router.push('/search?type=1&keyword=' + encodeURI(this.keyword))
      } else {
      }
    },
    orgList () {
      this.$api.get('/api/provider', {
        params: {
          provider_type: this.provider_type,
          _size: 10
        }
      }).then((res) => {
        this.org = res.data.collection
      })
    },
    callback (key) {
      this.provider_type = key
      this.orgList()
    },
    timeNow () {
      this.time = moment().utc().format('YYYY/MM/DD')
    },
    exit () {
      this.$Cookies.remove('userData')
      this.$Cookies.remove('phone')
      this.$Cookies.remove('idcard_no')
      this.$Cookies.remove('user_name')
      this.$Cookies.remove('uid')
      history.go(0)
    }
  },
  created () {
    this.orgList()
    this.timeNow()
    if (this.$Cookies.get('userData') !== '' && this.$Cookies.get('userData') !== undefined) {
      this.loginStatus = false
    }
  }
}
</script>
<style scoped src="../assets/css/home.css">
</style>
