<template>
  <ul class="mt-2">
    <li v-for="value in refObj" :key = "value.workorder_id">
      <p  style="width:100%;">
        <router-link :to="`/adviceinfo/${value.workorder_id}`" :title="value.order_desc">{{ value.order_desc }}</router-link>
      <span>{{ moment(created).format("YYYY-MM-DD") }}</span>
      </p>
    </li>
  </ul>
</template>
<script>
import base from '@/core/base.js'
export default {
  name: 'Case',
  mixins: [base]
}
</script>
