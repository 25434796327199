<template>
<div class="winBox">
    <ul class="scroll ccc" ref="ccc"  @mouseover="stopScroll" @mouseout="startScroll">
      <li v-for="value in refObj" :key="value.post_id">
        <router-link
          :to="`/article/detail/${value.post_id}`"
          :title="value.post_title"
          >{{ value.post_title }}</router-link
        >
      </li>
      <li v-for="value in refObj" :key="value.post_id">
        <router-link
          :to="`/article/detail/${value.post_id}`"
          :title="value.post_title"
          >{{ value.post_title }}</router-link
        >
      </li>
    </ul>
</div>
</template>
<script>
import base from '@/core/base.js'
export default {
  name: 'Notice',
  mixins: [base],
  data () {
    return {
      scrollW: 0,
      num: 0
    }
  },
  mounted () {
    this.scroll()
    // this.setWidth()
  },
  destroyed () {
    clearInterval(this.setInter)
  },
  methods: {
    setWidth: function () {
      this.$nextTick(() => {
        var content = this.$refs.ccc
        var li = content.children
        var width = 0
        for (let i = 0; i < li.length; i++) {
          width += li[i].clientWidth
          console.log(li[i].clientWidth)
        }
        content.style.width = width + 'px'
      })
    },
    stopScroll () {
      var target = this.$refs.ccc
      this.num = parseInt(target.style.left)
      clearInterval(this.setInter)
    },
    startScroll () {
      this.scroll()
    },
    scroll () {
      var target = this.$refs.ccc
      var num = this.num // 存移动的距离
      if (num < 0) {
        num = 0 - num
      }
      if (this.scrollW > 0) {
        num = this.scrollW
      }
      this.setInter = setInterval(() => {
        num++
        if (num >= target.offsetWidth - 1040) {
          num = 0
        }
        target.style.left = -num + 'px'
      }, 16)
    }
  }
}
</script>
<style scoped src="../../assets/css/notice.css"></style>
