<template>
  <span>
    <a-rate v-model="value" :tooltips="desc" />
    <span class="ant-rate-text">{{ desc[value - 1] }}</span>
  </span>
</template>
<script>
export default {
  name: 'Rate',
  data () {
    return {
      value: 3,
      desc: ['', '', '', '', '']
    }
  }
}
</script>
